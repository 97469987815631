import {SET_CURRENT_USER,ACCES} from "../actions/types";
const isEmpty = require("is-empty");

  const initialState = {
    isAuthenticated: false,
    user: {},
    acces:false
  };
  export default function auth(state = initialState, action) {
    switch (action.type) {
      case SET_CURRENT_USER:
        return {
          ...state,
          isAuthenticated: !isEmpty(action.payload) ? true:false,
          user: action.payload
        };
        case ACCES:
          return {
            ...state,
          acces:action.payload,
          };
      default:
        return state;
    }
  }
