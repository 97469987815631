import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import {
  deleteCandidacy,
} from "../../../actions/candidacy";

import { DeleteOutline } from "@material-ui/icons";
import Dialog from "../ManageQcms/Dialog";

const columns = [
  { id: "email", label: "E-mail" },
  { id: "title", label: "Titre" },
  { id: "created_at", label: "Date de création" },
  { id: "expires_in", label: "Date d'expiration" },
  { id: "action", label: "Action" },
];

const PendingTests = ({ tests, setLoader }) => {
  
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [idTest, setIdTest] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = (idTest) => {
    setIdTest(idTest);
    setOpenDialog(true);
  };

  const handleClickSave = () => {
    dispatch(deleteCandidacy(idTest));
    setOpenDialog(false);
  };

  const handleClickClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (tests){
      setRows(tests);
      setLoader(false);
    }
  }, [tests]);

  return (
    <Paper elevation={1}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              // .reverse()
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{
                      backgroundColor:
                        row.expires_in && new Date(row.expires_in) <= new Date()
                          ? "#ff7575ad"
                          : "",
                    }}
                  >
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>
                      {row.created_at && new Date(row.created_at).toISOString().split("T")[0]} à{" "}
                      { row.created_at && 
                        new Date(row.created_at)
                          .toISOString()
                          .split("T")[1]
                          .split(".")[0]
                      }
                    </TableCell>
                    <TableCell>
                      {row.expires_in &&
                        new Date(row.expires_in)
                          .toISOString()
                          .split("T")[0]}{" "}
                      à{" "}
                      {row.expires_in &&
                        new Date(row?.expires_in)
                          .toISOString()
                          .split("T")[1]
                          .split(".")[0]}
                    </TableCell>
                    <TableCell>
                      <DeleteOutline
                        title="Supprimer"
                        style={{ color: "red" }}
                        onClick={() => handleClickOpen(row._id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {openDialog && idTest ? (
        <Dialog
          open={openDialog}
          handleClickClose={handleClickClose}
          handleClickSave={handleClickSave}
          title="Suppression de candidature"
        />
      ) : null}
    </Paper>
  );
};
export default PendingTests;
