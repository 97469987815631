import React from "react";
import { withStyles, TextField, Grid } from "@material-ui/core";
const styleTest = (theme) => {
  return {
    rootTest: {
      padding: theme.spacing(3),
      height: "100%",
      maxWidth: 550,
      margin: "0 auto",
      "& input:disabled": { opacity: 0.5 },
    }
  };
};

const UpTest = ({ test, setTest, classes }) => {

  const handleChangeTest = (e) => {
    e.preventDefault();
    let testData = { ...test, [e.target.name]: e.target.value };
    if (testData.title && testData.nbQuestions) {
      testData.valid = true;
    } else {
      testData.valid = false;
    }
    setTest(testData);
  };

  return (
    <Grid container className={classes.rootTest}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <TextField
            name="title"
            id="title"
            value={test?.title}
            autoFocus
            required
            fullWidth
            margin="normal"
            label="Titre"
            variant="outlined"
            onChange={handleChangeTest}
          />
        </Grid>
      </Grid>{" "}
      <Grid container direction="row">
        <Grid item xs={12}>
          <TextField
            name="nbQuestions"
            id="nbQuestions"
            value={test?.nbQuestions}
            type="number"
            InputProps={{ inputProps: { min: 1, max: 30 } }}
            required
            fullWidth
            margin="normal"
            label="Nombre de questions"
            variant="outlined"
            onChange={handleChangeTest}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styleTest)(UpTest);
