import React, { useEffect, useState } from "react";
import Manage from "./Manage";
import Job from "./Job";
function OffreManagement() {
  const [selectItem, setSelectItem] = useState("");
  useEffect(()=>{
    window.scrollTo(0,100)
      },[])
  return (
    <div>
      <div className="selection">
        <div
          onClick={() => {
            selectItem === "add" ? setSelectItem("") : setSelectItem("add");
            selectItem !== ""
              ? selectItem === "add" &&
                document.querySelector(".selection").classList.remove("slide")
              : document.querySelector(".selection").classList.add("slide");
          }}
        >
          <span>Ajouter une offre</span>
          <img src="/sources/add.png" alt="add" />
        </div>
        <div
          onClick={() => {
            selectItem === "manage"
              ? setSelectItem("")
              : setSelectItem("manage");
            selectItem !== ""
              ? selectItem === "manage" &&
                document.querySelector(".selection").classList.remove("slide")
              : document.querySelector(".selection").classList.add("slide");
          }}
        >
          <span>Gestion des offres</span>
          <img src="/sources/manage.png" alt="manage" />
        </div>
      </div>
      <div>
        {selectItem === "add" && <Job />}
        {selectItem === "manage" && <Manage />}
      </div>
    </div>
  );
}

export default OffreManagement;
