import { GET_OFFRE, GET_ONE_OFFRE } from "../actions/types";

const initialState = {
  offres: [],
  offre:{}
};

export default function offre(state = initialState, action) {
  switch (action.type) {
    case GET_OFFRE:
      return {
        ...state,
        offres: action.payload,
      };
      case GET_ONE_OFFRE:
      return {
        ...state,
        offre: action.payload,
      };
    default:
      return state;
  }
}
