import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, withStyles } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FinishedTests from "./finishedTests";
import PandingTests from "./pandingTests";
import DeletedTests from "./deletedTests";
import {
  getTestResultsList,
  getTestPendingList,
  // getTestDeletedList,
} from "../../../actions/candidacy";
import LoadingSpinner from "../../../component/LoadingSpinner";

const styleManagement = (theme) => ({
  rootIndex: {
    border: `8px solid ${theme.palette.common.white}`,
    margin: 16,
    padding: "36px 0 0",
    background: `rgba(255,255,255,0.9)`,
    boxShadow: [
      `0px 16px 26px -10px ${theme.palette.primary.main}99`,
      theme.shadows[15],
    ],
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ResultsQcms = ({ history, classes }) => {
  const testsResults = useSelector((state) => state.candidacy.testsResults);
  const testsPending = useSelector((state) => state.candidacy.testsPending);
  const testDeleted = useSelector((state) => state.candidacy.testDeleted);
  const [value, setValue] = React.useState(0);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setLoader(true);
    setValue(newValue);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getTestResultsList());
    dispatch(getTestPendingList());
    // dispatch(getTestDeletedList());
  }, []);

  return (
    <Paper elevation={1} className={classes.rootIndex}>
      <div>
        <img
          src="/sources/arrow.svg"
          alt="back"
          style={{ cursor: "pointer", marginBottom: 20 }}
          onClick={() => history.push("/qcm")}
        />
      </div>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={"Terminé(" + testsResults.length + ")"}
            {...a11yProps(0)}
          />
          <Tab
            label={"En attente(" + testsPending.length + ")"}
            {...a11yProps(1)}
          />
          <Tab
            label={"Supprimé(" + testDeleted.length + ")"}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      {loader ? <LoadingSpinner /> : null}
      <TabPanel value={value} index={0}>
        <FinishedTests tests={testsResults} setLoader={setLoader} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PandingTests tests={testsPending} setLoader={setLoader} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DeletedTests tests={testDeleted} setLoader={setLoader} />
      </TabPanel>
    </Paper>
  );
};
export default withStyles(styleManagement)(ResultsQcms);
