import {
  GET_TESTS_RESULTS,
  GET_TESTS_PRNDING,
  // GET_TESTS_DELETED,
} from "../actions/types";

const initialState = {
  testsResults: [],
  testsPending: [],
  testDeleted: [],
};

export default function qcm(state = initialState, action) {
  switch (action.type) {
    case GET_TESTS_RESULTS:
      return {
        ...state,
        testsResults: action.payload,
      };
    case GET_TESTS_PRNDING:
      return {
        ...state,
        testsPending: action.payload.filter(e => e.deleted === false ),
        testDeleted: action.payload.filter(e => e.deleted === true ),
      };
    // case GET_TESTS_DELETED:
    //   return {
    //     ...state,
    //     testDeleted: action.payload,
    //   };
    default:
      return state;
  }
}
