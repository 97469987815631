import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, getAuthUser } from "../actions/authaction";
import "../style/home.css";
import { parseJwt } from './../util/parseJwt';
function TopPage({ history }) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.token || localStorage.token === "undefined")
      history.push("/");
    else dispatch(getAuthUser());
  }, []);

  useEffect(() => {
    const token = localStorage.token;
    if (token) {
      const decodedJwt = parseJwt(token);
      if (!decodedJwt.exp) dispatch(logoutUser());
      const expiryDate = new Date(decodedJwt.exp * 1000);
      if (expiryDate < Date.now()) {
        dispatch(logoutUser());
      }
    }
  });

  // useEffect(() => {
  //   if (localStorage.token) {
  //     const decoded = jwt_decode(localStorage.token);

  //     if (decoded.acces) {
  //       dispatch(setCurrentUser(decoded));
  //       dispatch(getOffre());
  //     } else {
  //       dispatch({
  //         type: ACCES,
  //         payload: true,
  //       });
  //       dispatch(logoutUser());
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onLogoutClick = () => {
    dispatch(logoutUser());
  };

  return (
    <div className="welcome-dash">
      <div>
        <img src="/sources/dash-welcome.png" alt="welcome" />
      </div>
      <div>
        <div>
          {auth.user.fname + " " + auth.user.lname}
          <img
            src="/sources/deconnexion.png"
            alt="deconnexion"
            title="déconnexion"
            onClick={onLogoutClick}
          />
        </div>
      </div>
    </div>
  );
}

export default TopPage;
