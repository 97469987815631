import React, { useState } from "react";
import "../style/dashboard.css";
import OffreManagement from "../component/OffreManagement";
import CongeManagement from "../component/CongeManagement";
import UtilSelection from "../component/UtilSelection";
import AutoManagement from "../component/AutoManagement";
import TopPage from "./TopPage";

function Dashboard() {
  const [selection, setSelection] = useState("");

  return (
    <div className="dash">
      <TopPage />
      <div style={{ marginBottom: "2%" }}>
        <a href="/home">Accueil</a>
      </div>
      <div className="selc">
        <div className="selc-map">
          <div>Catégories</div>
          <div>
            <div
              onClick={() => {
                selection === "offre"
                  ? setSelection("")
                  : setSelection("offre");
                document.querySelector(".selc").classList.add("slide");
              }}
            >
              <img src="/sources/team.png" alt="team" />
              <h3>Management des Offres</h3>
            </div>
            <div
              onClick={() => {
                selection === "conge"
                  ? setSelection("")
                  : setSelection("conge");
                document.querySelector(".selc").classList.add("slide");
              }}
            >
              <img src="/sources/team1.png" alt="team" />
              <h3>Management des Congés</h3>
            </div>
            <div
              onClick={() => {
                selection === "auto" ? setSelection("") : setSelection("auto");
                document.querySelector(".selc").classList.add("slide");
              }}
            >
              <img src="/sources/team2.png" alt="team" />
              <h3 style={{ textAlign:"center" }} >Management des demandes particulières</h3>
            </div>
            <div
              onClick={() => {
                selection === "util" ? setSelection("") : setSelection("util");
                document.querySelector(".selc").classList.add("slide");
              }}
            >
              <img src="/sources/team3.png" alt="team" />
              <h3>Management des Utilisateurs</h3>
            </div>
          </div>
        </div>

        <div className="selc-slide">
          <div>
            <img
              src="/sources/arrow.svg"
              alt="back"
              style={{ cursor: "pointer", marginBottom: 20 }}
              onClick={() => {
                document.querySelector(".selc").classList.remove("slide");
                setTimeout(() => {
                  setSelection("");
                }, 1200);
              }}
            />
          </div>
          {selection === "offre" && <OffreManagement />}
          {selection === "conge" && <CongeManagement />}
          {selection === "auto" && <AutoManagement />}
          {selection === "util" && <UtilSelection />}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
