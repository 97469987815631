import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ajouterUser} from "../actions/authaction";
import { GET_ERRORS } from "../actions/types";
import "../style/register.css";

function UtilManagement() {
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    fname: "",
    lname: "",
    email: "",
    fonction: "",
    password: "",
    password2: "",
    tel: "",
    address: "",
    error: {},
  });
  const [passtype, setpasstype] = useState("password");
  const [passvertype, setpassvertype] = useState("password");
  const [offreErreur, setOffreErreur] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (errors.ajouter) {
      setUser({
        ...user,
        fname: "",
        lname: "",
        email: "",
        fonction: "",
        password: "",
        password2: "",
        tel: "",
        address: "",
      })
      setOffreErreur(false);
      setTimeout(() => {
        setOffreErreur(true);
      }, 3300);
     user.fname===""&&
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    }
  });

  useEffect(() => {
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (errors) setUser({ ...user, error: errors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const onChange = (e) => {
    setUser({ ...user, [e.target.id]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
    const newUser = {
      fname: user.fname,
      lname: user.lname,
      fonction: user.fonction,
      email: user.email.toLowerCase(),
      password: user.password,
      password2: user.password2,
      tel: user.tel,
      address: user.address,
    };
    dispatch(ajouterUser(newUser));
  };
  return (
    <>
      <div style={{ padding: "5%" }}>
        <div>
          <div className="add-user">
            <h2>Ajouter un utilisateur</h2>
          </div>

          <form onSubmit={onSubmit}>
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="fname">Prénom</label>
              <input
                onChange={onChange}
                value={user.fname}
                id="fname"
                type="text"
                className="form-control"
                // autoFocus
              />

              <span className={user.error.fname && "red-text"}>
                {user.error.fname}
              </span>
            </div>
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="lname">Nom</label>
              <input
                onChange={onChange}
                value={user.lname}
                id="lname"
                type="text"
                className="form-control"
              />

              <span className={user.error.lname && "red-text"}>
                {user.error.lname}
              </span>
            </div>
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="email">Email</label>
              <input
                onChange={onChange}
                value={user.email}
                id="email"
                type="email"
                className="form-control"
              />

              <span className={user.error.email && "red-text"}>
                {user.error.email}
              </span>
            </div>
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="fonction">Fonction</label>
              {/* <input
                onChange={onChange}
                value={user.fonction}
                id="fonction"
                type="text"
                className="form-control"
              /> */}
              <select
            onChange={onChange}
            id="fonction"
            value={user.fonction}
            className="form-control"
            style={{ border: user.fonction && "1px solid red" }}
          >
            <option value="">sélectionner une fonction</option>
            <option value="Manager">Manager</option>
            <option value="RH Manager">RH Manager</option>
            <option value="Développeur">Développeur</option>
            
          </select>

              <span className={user.error.fonction && "red-text"}>
                {user.error.fonction}
              </span>
            </div>
            <div className="form-outline mb-4 mp-form">
              <label className="form-label" htmlFor="password">Mot de passe</label>
              <input
                onChange={onChange}
                value={user.password}
                id="password"
                type={passtype}
                style={{ paddingRight: 25, boxSizing: "border-box" }}
                className="form-control"
              />

              <span className={user.error.password && "red-text"}>
                {user.error.password}
              </span>
              <span
                onClick={() => {
                  if (passtype === "password") setpasstype("text");
                  else setpasstype("password");
                }}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 39,
                  color: "gray",
                }}
                title={
                  passtype === "password" ? "Show password" : "Hide password"
                }
              >
                {passtype === "password" ? (
                  <i className="far fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </span>
            </div>
            <div className="form-outline mb-4 mp-form">
              <label className="form-label" htmlFor="password2">Confirmez le mot de passe</label>
              <input
                onChange={onChange}
                value={user.password2}
                id="password2"
                type={passvertype}
                style={{ paddingRight: 25, boxSizing: "border-box" }}
                className="form-control"
              />

              <span className={user.error.password2 && "red-text"}>
                {user.error.password2}
              </span>
              <span
                onClick={() => {
                  if (passvertype === "password") setpassvertype("text");
                  else setpassvertype("password");
                }}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 39,
                  color: "gray",
                }}
                title={
                  passvertype === "password" ? "Show password" : "Hide password"
                }
              >
                {passvertype === "password" ? (
                  <i className="far fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </span>
            </div>
            <div className="form-outline mb-4">
              <label className="active form-label" htmlFor="tel">
                Entrez le numéro de téléphone (ex:+216xxxxxxxx)
              </label>
              <input
                onChange={onChange}
                value={user.tel}
                id="tel"
                type="tel"
                className="form-control"
              />

              <span className={user.error.tel && "red-text"}>
                {user.error.tel}
              </span>
            </div>
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="address">Entrez l'adresse</label>
              <input
                onChange={onChange}
                value={user.address}
                id="address"
                type="text"
                className="form-control"
              />

              <span className={user.error.address && "red-text"}>
                {user.error.address}
              </span>
            </div>
            <div style={{ paddingLeft: "11.250px" }}>
              <button
                style={{
                  width: "fit-content",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  margin: "auto auto",
                  height: "45px",
                }}
                type="submit"
                className="btn btn-primary btn-block "
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className="offre-alert"
        style={{
          opacity: offreErreur && 0,
          visibility: offreErreur && "hidden",
        }}
      >
        Utilisateur ajouté avec succés
      </div>
    </>
  );
}

export default UtilManagement;
