import React, { useEffect } from "react";
import "../../style/home.css";
import "../../style/dashboard.css";
import TopPage from "../TopPage";

function QCM({ history }) {
  useEffect(() => {
    localStorage.removeItem("step");
    localStorage.removeItem("qstNum");
    localStorage.removeItem("questions");
    localStorage.removeItem("testTitle");
  }, []);
  return (
    <div className="dash">
      <TopPage />
      <div style={{ marginBottom: "2%" }}>
        <a href="/home">Accueil</a>
      </div>

      <div className="selc">
        <div className="selc-map">
          <div>QCM</div>
        </div>
      </div>
      <div className="rowHome">
        <div className="_gauche">
          <div className="cardRow" onClick={() => history.push(`/addQCM`)}>
            <img src="/sources/team.png" alt="team" />
            <h3>Ajouter un test</h3>
          </div>
        </div>
        <div className="_droite">
          <div
            className="cardRow"
            style={{ float: "right" }}
            onClick={() => history.push(`/manageQCM`)}
          >
            <img src="/sources/team2.png" alt="team" />
            <h3>Gérer les tests</h3>
          </div>
        </div>
      </div>
      <div className="rowHome" style={{ marginTop: "5%" }}>
        <div className="_gauche">
          <div className="cardRow" onClick={() => history.push(`/resultQCM`)}>
            <img src="/sources/team.png" alt="team" />
            <h3>Résultats des candidats</h3>
          </div>
        </div>

        {/* <div className="_droite">
          <div className="cardRow" style={{ float:"right" }} onClick={() => history.push(`/pendingQCM`)} >
            <img src="/sources/team2.png" alt="team" />
            <h3>Tests en attentes</h3>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default QCM;
