import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { sendTest } from "../../../actions/testAction";
import { useDispatch, useSelector } from "react-redux";
import { TEST_SENT } from "../../../actions/types";
export default function SendQcmDialog(props) {
  const testsent = useSelector((state) => state.qcmTest.testsent);
  console.log("testsent", testsent);
  const { idTest, open } = props;

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [error, setError] = useState();

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClose = () => {
    props.handleClickClose();
  };

  const handleSave = () => {
    dispatch(sendTest({ email, idTest }));
  };

  const validate = (fieldValues) => {
    let temp = error;

    if (fieldValues) {
      temp = fieldValues ? "" : "This field is required.";
      if (fieldValues)
        temp = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues)
          ? ""
          : "Email is not valid.";
    }
    setError(temp);
  };

  const handleInputValue = (e) => {
    const { value } = e.target;
    setEmail(value);
    validate(value);
  };

  useEffect(() => {}, [props]);
  useEffect(() => {
    if (testsent) {
      setTimeout(() => {
        props.handleClickClose();
        dispatch({
          type: TEST_SENT,
          payload: "",
        });
      }, 4000);
    }
  }, [testsent]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>E-mail</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Pour envoyer ce test, veuillez saisir ici l'adresse e-mail du
          candidat.
        </DialogContentText>
        <br />
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onBlur={handleInputValue}
          onChange={onChange}
          helperText={error ? error : null}
        />
        {testsent ? (
          <span style={{ color: "red" }}>{testsent} </span>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button onClick={handleSave} disabled={!email}>
          Envoyer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
