import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOffre, offreDelete } from "../actions/offreaction";
import "../style/manage.css";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { useHistory } from "react-router-dom";
import { GET_ERRORS } from "../actions/types";

function Manage() {
  const [modal, setModal] = useState(false);
  const [offreId, setOffreId] = useState("");
  const offres = useSelector((state) => state.offres.offres);
  const error = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const history = useHistory();

  const toggle = () => {
    setModal(!modal);
  };
  const deleteOffre = (id) => {
    dispatch(offreDelete(id));
  };
  const handleDelete = (id) => {
    console.log("id", id);
    toggle();
    setOffreId(id);
  };

  useEffect(() => {
    dispatch(getOffre());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error && error.delete) {
      toggle();
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    }
  });

  return (
    <div>
      <div className="add-offre">
        <h3>Gestion des offres</h3>
      </div>
      {offres && offres.length !== 0 ? (
        <div className="row offres-list">
          {offres
            .slice(0)
            .reverse()
            .map((el) => {
              return (
                <div className="col-lg-6  col-12" key={el._id}>
                  <div className="offre-card">
                    <div>
                      <h2>
                        {" "}
                        <strong>
                          {el.titre.length <= 20
                            ? el.titre
                            : el.titre.slice(0, 20) + ".."}
                        </strong>
                      </h2>
                      <p>{el.created_at.split("T")[0]}</p>
                    </div>
                    <div className="content">
                      <p>
                        {el.description.length <= 160
                          ? el.description
                          : el.description.slice(0, 160) + ".."}
                      </p>
                    </div>
                    {el.pays && (
                      <p className="place-offre">
                        <img src="/sources/offre-location.png" alt="location" />
                        {el.pays.length <= 7
                          ? el.pays
                          : el.pays.slice(0, 7) + ".."}
                      </p>
                    )}
                    <div className="manage_offer">
                      <p>
                        <img
                          src="/sources/offre-edit.png"
                          alt="edit"
                          title="modifier"
                          onClick={() => history.push(`/offres/${el._id}`)}
                        />
                      </p>
                      <p>
                        <img
                          src="/sources/offre-delete.png"
                          alt="supprimer"
                          title="supprimer"
                          onClick={() => deleteOffre(el._id)}
                        />
                      </p>
                    </div>
                    {new Date(el.dateExpiration) <= new Date() && (
                      <p className="expiration">expiré</p>
                    )}
                    <img src="/sources/offre-logo.png" alt="logo" />
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        offres && (
          <div className="no-offre">
            <p>
              <i className="fas fa-exclamation"></i>
              Pas d'offres disponibles pour le moment.
            </p>
          </div>
        )
      )}
      {/* <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Supprimer l'offre</MDBModalHeader>
        <MDBModalBody>
          êtes-vous sûr de vouloir supprimer l'offre ?
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            className="no-delete"
            onClick={toggle}
            style={{
              color: "#0380b1",
              border: "1px solid #0380b1",
              borderRadius: 5,
            }}
          >
            Fermer
          </MDBBtn>
          <MDBBtn
            className="yes-delete"
            onClick={() => deleteOffre(offreId)}
            style={{ color: "white", borderRadius: 5 }}
          >
            Supprimer
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal> */}
    </div>
  );
}

export default Manage;
