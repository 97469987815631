import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getConge, congeValide } from "../actions/authaction";
import "../style/conge.css";
// import {
//   MDBBtn,
//   MDBModal,
//   MDBModalBody,
//   MDBModalHeader,
//   MDBModalFooter,
// } from "mdbreact";
import { GET_ERRORS } from "../actions/types";

function CongeManagement() {
  const dispatch = useDispatch();
  const conge = useSelector((state) => state.conge.conge);
  const error = useSelector((state) => state.errors);
  const users = useSelector((state) => state.users.users);
  // const [congeId, setCongeId] = useState("");
  const [modal, setModal] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);
  useEffect(() => {
    dispatch(getConge());
    dispatch(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (error.conge) {
      toggle();
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    }
  });
  const toggle = () => {
    setModal(!modal);
  };
  // const valideConge = (id) => {
  //   dispatch(congeValide(id));
  // };

  // const Validate = (id) => {
  //   console.log('id', id)
  //   setModal(!modal);
  //   // toggle();
  //   setCongeId(id);
  // };

  return (
    <div style={{ marginTop: 20, width: "100%" }}>
      <div className="add-offre">
        <h3>Gestion des congés</h3>
      </div>
      {conge && users.length && conge.length !== 0 ? (
        <>
          {conge
            .slice(0)
            .reverse()
            .map((el, id) => {
              return (
                <div key={el._id} className="collap-conge">
                  <div>
                    <div>
                      <img
                        src={users.find((elm) => elm._id === el.userId)?.avatar}
                        alt="avatar"
                      />
                    </div>

                    <div>
                      {users.find((elm) => elm._id === el.userId)?.fname +
                        " " +
                        users.find((elm) => elm._id === el.userId)?.lname}
                    </div>
                  </div>
                  <div>
                    <div>
                      <img src="/sources/calendar.png" alt="calendar" />
                    </div>
                    <div>{el.start + " / " + el.end}</div>
                  </div>
                  <div>{el.raison}</div>
                  <div>
                    <span style={{ color: el.etat ? "#37c95c" : "red" }}>
                      {el.etat ? "Validé" : "Non validé"}
                    </span>
                    {el.justif && (
                      <a href={el.justif}>
                        <img src="/sources/icon-pdf.png" alt="pdf" />
                      </a>
                    )}
                    {!el.etat && (
                      <img
                        src="/sources/valider.png"
                        alt="valider"
                        title="valider"
                        onClick={() => dispatch(congeValide(el._id)) }
                        
                      ></img>
                    )}
                  </div>
                </div>
              );
            })}
        </>
      ) : (
        conge && (
          <div className="no-offre">
            <p>
              <i className="fas fa-exclamation"></i>
              Liste vide.
            </p>
          </div>
        )
      )}
      {/* <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>
          Valider la demande de congé
        </MDBModalHeader>
        <MDBModalBody>
          êtes-vous sûr de vouloir valider la demande ?
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            className="no-delete"
            onClick={toggle}
            style={{
              color: "#0380b1",
              border: "1px solid #0380b1",
              borderRadius: 5,
            }}
          >
            Fermer
          </MDBBtn>
          <MDBBtn
            className="yes-delete"
            onClick={() => valideConge(congeId)}
            style={{ color: "white", borderRadius: 5 }}
          >
            Valider
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal> */}
    </div>
  );
}

export default CongeManagement;
