import axios from "axios";
import setAuthToken from "../token/authtoken";
import { GET_ERRORS, GET_TESTS_RESULTS, GET_TESTS_PRNDING } from "./types";

export const getTestResultsList = () => (dispatch) => {
    setAuthToken(localStorage.token);
    axios
      .get("/candidacy/resultsCandidacy")
      .then((res) => {
        dispatch({
          type: GET_TESTS_RESULTS,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      );
  };

  export const getTestPendingList = () => (dispatch) => {
    setAuthToken(localStorage.token);
    axios
      .get("/candidacy/pendingCandidacy")
      .then((res) => {
        dispatch({
          type: GET_TESTS_PRNDING,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      );
  };

  // export const getTestDeletedList = () => (dispatch) => {
  //   setAuthToken(localStorage.token);
  //   axios
  //     .get("/candidacy/deletedCandidacy")
  //     .then((res) => {
  //       dispatch({
  //         type: GET_TESTS_DELETED,
  //         payload: res.data,
  //       });
  //     })
  //     .catch((err) =>
  //       dispatch({
  //         type: GET_ERRORS,
  //         payload: err.response.data,
  //       })
  //     );
  // };

  export const deleteCandidacy= (id) => (dispatch) => {
    setAuthToken(localStorage.token);
    axios
      .delete(`/candidacy/delete/${id}`)
      .then((res) => {
        dispatch({
          type: GET_TESTS_PRNDING,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      );
  };