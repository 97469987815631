import {  GET_TEST, GET_TESTS, TEST_SENT } from "../actions/types";

const initialState = {
  qcm: {},
  qcmList: [],
  testsent:""
};

export default function qcm(state = initialState, action) {
  switch (action.type) {
    case GET_TEST:
      return {
        ...state,
        qcm: action.payload
      };
    case GET_TESTS:
      return {
        ...state,
        qcmList: action.payload
      };
      case TEST_SENT:
        return{
          ...state,
          testsent: action.payload
        }
    default:
      return state;
  }
}
