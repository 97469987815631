import React from "react";
import { Grid } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import Typography from "@mui/material/Typography";
import "../../../style/viewTestResult.css";

const ViewTestResult = ({ data }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom component="div">
          {data.title}
        </Typography>
        <br />
        <ol>
          {data.result?.map((qst, index) => {
            let date = new Date(qst.duration);
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();
            
            return (
              <li key={"qst" + index} style={{ marginBottom: "2%" }}>
                <span className="qstAlign">
                  <h6 className="qst">{qst.question}</h6>
                  <span className="time">
                    {"("}
                    {minutes}:{seconds}
                    {")"}
                  </span>
                </span>

                {qst.nbPropositions > 0 ? (
                  <div>
                    <ul>
                      {qst.propositions?.map((prop, index) => (
                        <li key={"prop" + index} style={{ marginBottom: 10 }}>
                          <b
                            className={
                              prop.isCorrect && prop.response
                                ? "message answer"
                                : !prop.isCorrect && prop.response
                                ? "message wrong"
                                : ""
                            }
                          >
                            {prop.proposition}
                            {prop.isCorrect ? (
                              <Check style={{ color: "green" }} />
                            ) : null}
                          </b>{" "}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : qst.hasFreeField ? (
                  <p style={{ width: "97%" }}>{qst.freeField}</p>
                ) : null}
              </li>
            );
          })}
        </ol>
      </Grid>
    </Grid>
  );
};
export default ViewTestResult;
