export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_OFFRE = "GET_OFFRE";
export const GET_CONGE = "GET_CONGE";
export const GET_USER = "GET_USER";
export const ACCES = "ACCES";
export const GET_AUTO = "GET_AUTO";
export const GET_ONE_OFFRE = "GET_ONE_OFFRE";
export const GET_TEST = "GET_TEST";
export const GET_TESTS = "GET_TESTS";
export const GET_TESTS_RESULTS = "GET_TESTS_RESULTS"
export const GET_TESTS_PRNDING = "GET_TESTS_PRNDING"
export const TEST_SENT = "TEST_SENT"