import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { loginUser } from "../actions/authaction";
import { GET_ERRORS } from "../actions/types";
import "../style/login.css";
function Login({ history }) {
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const [passtype, setpasstype] = useState("password");
  const [userlog, setUserlog] = useState({
    email: "",
    password: "",
    error: {},
  });

  useEffect(() => {
    if (localStorage.token && localStorage.token !== "undefined") history.push("/home");
  });

  useEffect(() => {
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    if (errors) setUserlog({ ...userlog, error: errors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const onChange = (e) => {
    setUserlog({ ...userlog, [e.target.id]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
    const userData = {
      email: userlog.email.toLowerCase(),
      password: userlog.password,
    };
    dispatch(loginUser(userData));
  };

  return (
    <div className="login-page">
      <div>
        <div >
          <h2>
            Connectez-vous ci-dessous
          </h2>
          {/* <p className="grey-text text-darken-1">
          Vous n'avez pas de compte?{" "}
            <Link
              to="/register"
              onClick={() =>
                dispatch({
                  type: GET_ERRORS,
                  payload: {},
                })
              }
            >
              S'inscrire
            </Link>
          </p> */}
        </div>
        <form onSubmit={onSubmit}>
          <div className="form-outline mb-4 mp-form">
            {/* <label className="form-label" htmlFor="email">
              E-mail
            </label> */}
            <input
              type="email"
              id="email"
              className="form-control"
              onChange={onChange}
              value={userlog.email}
              autoFocus
              placeholder="E-mail"
            />
            <img src="/sources/email.png" alt="email"/>
            <span className={userlog.error.email && "red-text"}>
              {userlog.error.email}
            </span>
          </div>

          <div className="form-outline mb-4 mp-form">
            {/* <label className="form-label" htmlor="password">
              Mot de passe
            </label> */}
            <input
              id="password"
              className="form-control"
              onChange={onChange}
              value={userlog.password}
              type={passtype}
              style={{ paddingRight: 35, boxSizing: "border-box" }}
              placeholder="Mot de passe"
            />
            <img src="/sources/mp.png" alt="mp"/>
            <span className={userlog.error.password && "red-text"}>
              {userlog.error.password}
            </span>
            <span
              onClick={() => {
                if (passtype === "password") setpasstype("text");
                else setpasstype("password");
              }}
              style={{
                position: "absolute",
                right: 10,
                top: 10,
                color: "gray",
              }}
              title={
                passtype === "password" ? "Show password" : "Hide password"
              }
            >
              {passtype === "password" ? (
                <i className="far fa-eye"></i>
              ) : (
                <i className="fas fa-eye-slash"></i>
              )}
            </span>
          </div>
          <button type="submit" className="btn">
          S'identifier
          </button>
          <span style={{ textAlign:"center" }} className={userlog.error.notallowed && "red-text"}>
              {userlog.error.notallowed}
            </span>
        </form>
      </div>
    </div>
  );
}

export default Login;
