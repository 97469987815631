import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bloqueUser, getUser, validUser } from "../actions/authaction";
import "../style/util.css";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { GET_ERRORS } from "../actions/types";
function UtilList() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const auth = useSelector((state) => state.auth);
  const errors = useSelector((state) => state.errors);
  const [UtilId, setUtilId] = useState("");
  const [modal, setModal] = useState(false);
  const [modalBlock, setModalBlock] = useState(false);
  const [offreErreur, setOffreErreur] = useState(true);
  useEffect(() => {
    dispatch(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (errors.bloque) {
      toggle();
      setOffreErreur(false);
      setTimeout(() => {
        setOffreErreur(true);
      }, 3300);
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    }
  });

  useEffect(() => {
    if (errors.valid) {
      toggleBlock();
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    }
  });

  const toggle = () => {
    setModal(!modal);
  };
  const toggleBlock = () => {
    setModalBlock(!modalBlock);
  };
  const bloqueUtil = (id) => {
    dispatch(bloqueUser(id));
  };
  const ValidUtil = (id) => {
    dispatch(validUser(id));
  };
  return (
    <div>
      <div className="add-user">
        <h2>Gestion des utilisateurs</h2>
      </div>
      {users
        .slice(0)
        .reverse()
        .map((el, id) => {
          return (
            <div key={el._id} className="user_list">
              <div>
                <div>
                  <img src={el.avatar} alt="avatar" />
                  <div
                    style={{ backgroundColor: el.online ? "#00ff00" : "gray" }}
                  ></div>
                </div>

                <div>{el.fname + " " + el.lname}</div>
              </div>
              <div>
                <div>
                  <img src="/sources/calendar.png" alt="calendar" />
                </div>
                <div>{el.fonction}</div>
              </div>
              <div>
                <p style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/sources/adresse.png"
                    alt="adresse"
                    style={{ marginRight: 10 }}
                  ></img>
                  {el.address}
                </p>
                <p style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/sources/tel.png"
                    alt="tel"
                    style={{ marginRight: 10 }}
                  ></img>
                  {el.tel}
                </p>
                <p style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/sources/email-icon.png"
                    alt="email"
                    style={{ marginRight: 10 }}
                  ></img>{" "}
                  {el.email}
                </p>
              </div>
              <div>
                {el._id === auth.user.id ? (
                  <span>Admin</span>
                ) : (
                  <>
                    {el.blocage && <span style={{ color: "red" }}>Bloqué</span>}
                    {!el.blocage ? (
                      <img
                        src="/sources/bloquer.png"
                        alt="bloquer"
                        title="Bloquer"
                        onClick={() => {bloqueUtil(el._id);
                        }}
                      ></img>
                    ) : (
                      <img
                        src="/sources/valider.png"
                        alt="valider"
                        title="Supprimer le blocage"
                        onClick={() => {
                          // toggleBlock();
                          ValidUtil(el._id);
                        }}
                      ></img>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
      {/* <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>
          Valider le blocage de l'utilisateur
        </MDBModalHeader>
        <MDBModalBody>
          êtes-vous sûr de vouloir bloquer{" "}
          {users.length !== 0 &&
            UtilId &&
            users.find((el) => el._id === UtilId).fname}{" "}
          {users.length !== 0 &&
            UtilId &&
            users.find((el) => el._id === UtilId).lname}
          ?
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            className="no-delete"
            onClick={toggle}
            style={{
              color: "#0380b1",
              border: "1px solid #0380b1",
              borderRadius: 5,
            }}
          >
            Fermer
          </MDBBtn>
          <MDBBtn
            className="yes-delete"
            onClick={() => bloqueUtil(UtilId)}
            style={{ color: "white", borderRadius: 5 }}
          >
            Valider
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      <MDBModal isOpen={modalBlock} toggle={toggleBlock}>
        <MDBModalHeader toggle={toggleBlock}>
          Supprimer le blocage de l'utilisateur
        </MDBModalHeader>
        <MDBModalBody>
          êtes-vous sûr de vouloir supprimer le blocage de{" "}
          {users.length !== 0 &&
            UtilId &&
            users.find((el) => el._id === UtilId).fname}{" "}
          {users.length !== 0 &&
            UtilId &&
            users.find((el) => el._id === UtilId).lname}
          ?
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            className="no-delete"
            onClick={toggleBlock}
            style={{
              color: "#0380b1",
              border: "1px solid #0380b1",
              borderRadius: 5,
            }}
          >
            Fermer
          </MDBBtn>
          <MDBBtn
            className="yes-delete"
            onClick={() => ValidUtil(UtilId)}
            style={{ color: "white", borderRadius: 5 }}
          >
            Valider
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal> */}
      <div
        className="offre-alert"
        style={{
          opacity: offreErreur && 0,
          visibility: offreErreur && "hidden",
        }}
      >
        Utilisateur bloquer avec succés
      </div>
    </div>
  );
}

export default UtilList;
