import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  MobileStepper,
  TextField,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SwipeableViews from "react-swipeable-views";
import { green } from "@material-ui/core/colors";

const styleQst = () => {
  return {
    rootQst1: {
      height: "100%",
      maxWidth: 800,
      margin: "0 auto",
      "& input:disabled": { opacity: 0.5 },
    },
    rootQst2: {
      maxWidth: 1000,
      margin: "0 auto",
    },
  };
};

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Questions = ({ nbQuestions, setValidQst, saveLastQst, classes }) => {
  const [listQuestions, setListQuestions] = useState([]);
  const [activeQst, setActiveQst] = useState();

  const handleChange = (index) => (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let oldQstList = [...listQuestions];
    let oldQst = { ...oldQstList[index] };
    let newQst = {};
    if (name === "nbPropositions" && parseInt(value) >= 2) {
      let propositions = [];
      if (oldQst.propositions.length === 0) {
        propositions = Array(parseInt(value)).fill({
          proposition: "",
          isCorrect: false,
        });
      } else {
        propositions = [...oldQst.propositions];
        if (parseInt(value) < oldQst.propositions.length) {
          propositions.splice(value,oldQst.propositions.length-1);
        }
      }
      newQst = { ...oldQst, [name]: parseInt(value) };
      newQst = { ...newQst, hasFreeField: false };
      newQst = { ...newQst, propositions: propositions };
    } else {
      newQst = { ...oldQst, [name]: value };
    }
    oldQstList[index] = newQst;
    setListQuestions(oldQstList);
  };

  const handleChangeTime = (index) => (value) => {
    let oldQstList = [...listQuestions];
    let oldQst = { ...oldQstList[index] };
    let newQst = { ...oldQst, duration: value };
    oldQstList[index] = newQst;
    setListQuestions(oldQstList);
  };

  const handleChangeChecked = (index) => (e) => {
    e.preventDefault();
    const { name, checked } = e.target;
    let oldQstList = [...listQuestions];
    let oldQst = { ...oldQstList[index] };
    let newQst = { ...oldQst, [name]: checked };
    if (name === "isMultiAnswer") {
      newQst = { ...newQst, hasFreeField: false };
    } else {
      newQst = { ...newQst, isMultiAnswer: false };
      newQst = { ...newQst, nbPropositions: 0 };
    }
    oldQstList[index] = newQst;
    setListQuestions(oldQstList);
  };

  const handleChangeProp = (idxQst, idxProp) => (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let oldQstData = [...listQuestions];
    let oldQstProps = { ...oldQstData[idxQst].propositions[idxProp] };
    let newQstProps = { ...oldQstProps, [name]: value };
    oldQstData[idxQst].propositions[idxProp] = newQstProps;
    setListQuestions(oldQstData);
  };

  const handleCheckedCorrect = (idxQst, idxProp) => (e) => {
    e.preventDefault();
    const { name, checked } = e.target;
    let oldQstData = [...listQuestions];
    let oldQstProps = { ...oldQstData[idxQst].propositions };
    let newQstProps = { ...oldQstProps[idxProp], [name]: checked };
    oldQstData[idxQst].propositions[idxProp] = newQstProps;

    //if question has only one correct answer
    if (!oldQstData[idxQst].isMultiAnswer) {
      if (oldQstData[idxQst].nbPropositions >= 2) {
        for (let i = 0; i < oldQstData[idxQst].nbPropositions; i++) {
          if (i !== idxProp) {
            let unchekedProps = { ...oldQstProps[i], isCorrect: false };
            oldQstData[idxQst].propositions[i] = unchekedProps;
          }
        }
      }
    }
    setListQuestions(oldQstData);
  };

  const handleNext = () => {
    localStorage.setItem("questions", JSON.stringify(listQuestions));
    if (!saveLastQst) {
      setActiveQst((prevActiveStep) => prevActiveStep + 1);
      localStorage.setItem("qstNum", activeQst + 1);
    }
  };

  const handleBack = () => {
    setActiveQst((prevActiveStep) => prevActiveStep - 1);
    localStorage.setItem("qstNum", activeQst - 1);
  };

  const handleChangeSwipeable = (index) => {
    setActiveQst(index);
    localStorage.setItem("qstNum", index);
  };

  useEffect(() => {
    if (activeQst === nbQuestions - 1) setValidQst(true);
    else setValidQst(false);
  }, [activeQst, nbQuestions]);

  useEffect(() => {
    if (nbQuestions) {
      let nb = parseInt(nbQuestions);
      let qstStep = localStorage.getItem("qstNum");
      let qsts = JSON.parse(localStorage.getItem("questions"));
      if (qsts && qsts.length >= 2) {
        if (qsts.length === nb) {
          setListQuestions(qsts);
          qstStep = Number(localStorage.getItem("qstNum"));
        } else {
          let array = Array(nb).fill({
            question: "",
            duration: "",
            nbPropositions: 0,
            propositions: [],
            isMultiAnswer: false,
            hasFreeField: false,
          });
          setListQuestions(array);
          qstStep = 0;
        }
      } else {
        let array = Array(nb).fill({
          question: "",
          duration: "",
          nbPropositions: 0,
          propositions: [],
          isMultiAnswer: false,
          hasFreeField: false,
        });
        setListQuestions(array);
        qstStep = 0;
      }

      localStorage.setItem("qstNum", qstStep);
      setActiveQst(qstStep);
    }
  }, [nbQuestions]);

  useEffect(() => {
    if (saveLastQst) handleNext();
  }, [saveLastQst]);

  useEffect(() => {}, [activeQst]);
  console.log("listQuestions", listQuestions);
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.rootQst2}
    >
      <div>
        <SwipeableViews
          index={activeQst}
          onChangeIndex={handleChangeSwipeable}
          enableMouseEvents
        >
          {listQuestions &&
            listQuestions?.map((qst, index) => (
              <Grid container className={classes.rootQst1} key={`qst-${index}`}>
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <TextField
                      name="question"
                      id={"Question" + parseInt(activeQst + 1)}
                      required
                      fullWidth
                      margin="normal"
                      label={"Question " + parseInt(activeQst + 1)}
                      variant="outlined"
                      onChange={handleChange(index)}
                      value={qst.question}
                    />
                  </Grid>
                </Grid>{" "}
                <Grid container spacing={2} direction="row">
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        ampmInClock
                        views={["minutes", "seconds"]}
                        inputFormat="mm:ss"
                        mask="__:__"
                        label="Durée"
                        onChange={handleChangeTime(index)}
                        value={qst.duration ? qst.duration : new Date()}
                        renderInput={(params) => (
                          <TextField
                            name="duration"
                            id={"duration" + parseInt(activeQst + 1)}
                            required
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="nbPropositions"
                      id={"nbPropositions" + parseInt(activeQst + 1)}
                      required
                      fullWidth
                      type="number"
                      InputProps={{ inputProps: { min: 2, max: 6 } }}
                      margin="normal"
                      label="Nombre de propositions"
                      variant="outlined"
                      onChange={handleChange(index)}
                      value={qst.nbPropositions}
                    />
                  </Grid>
                </Grid>{" "}
                <Grid container spacing={2} direction="row">
                  <Grid item xs={6}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={qst.isMultiAnswer}
                              onChange={handleChangeChecked(index)}
                              name="isMultiAnswer"
                            />
                          }
                          label="Multi-réponse"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={qst.hasFreeField}
                              onChange={handleChangeChecked(index)}
                              name="hasFreeField"
                              disabled={qst.nbPropositions ? true : false}
                            />
                          }
                          label="Réponse libre"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                {qst.nbPropositions && qst.nbPropositions >= 2 ? (
                  <Grid container spacing={2} direction="row">
                    {[...Array(qst.nbPropositions)]?.map((prop, idx) => (
                      <Grid
                        item
                        xs={6}
                        style={{ display: "flex" }}
                        key={`prop-${idx}`}
                      >
                        <Grid item xs={11}>
                          <TextField
                            name="proposition"
                            id={"proposition" + parseInt(idx + 1)}
                            required
                            fullWidth
                            margin="normal"
                            label={"Proposition " + parseInt(idx + 1)}
                            variant="outlined"
                            onChange={handleChangeProp(index, idx)}
                            value={qst?.propositions[idx]?.proposition}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <FormControl
                            required
                            // error={error}
                            component="fieldset"
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <GreenCheckbox
                                    checked={qst?.propositions[idx]?.isCorrect}
                                    onChange={handleCheckedCorrect(index, idx)}
                                    name="isCorrect"
                                  />
                                }
                                // label="Correct"
                              />
                            </FormGroup>
                            {/* <FormHelperText>You can display an error</FormHelperText> */}
                          </FormControl>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : null}
              </Grid>
            ))}
        </SwipeableViews>

        <MobileStepper
          id="MobileStepperQst"
          steps={parseInt(nbQuestions)}
          position="static"
          variant="text"
          activeStep={activeQst}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeQst === nbQuestions - 1}
            >
              Suivant
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeQst === 0}
            >
              <KeyboardArrowLeft />
              Précédent
            </Button>
          }
        />
      </div>
    </Grid>
  );
};

Questions.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export default withStyles(styleQst, { withTheme: true })(Questions);
