import axios from "axios";
import setAuthToken from "../token/authtoken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  GET_CONGE,
  GET_USER,
  GET_AUTO,
} from "./types";

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/user/register", { ...userData, acces: true })
    .then((res) => {
      history.push("/"); // re-direct to login on successful register
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Add User
export const ajouterUser = (userData) => (dispatch) => {
  axios
    .post("/user/ajouter", userData)
    .then((res) => {
      dispatch({
        type: GET_ERRORS,
        payload: { ajouter: "ok" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/user/login", userData)
    .then((res) => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      if (token) {
        localStorage.setItem("token", token);
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);
        // Set current user
        dispatch(setCurrentUser(decoded));
        dispatch({
          type: GET_ERRORS,
          payload: {},
        });
      }else{
        dispatch({
          type: GET_ERRORS,
          payload: "token "+token,
        })
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//// get users
export const getUser = () => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .get("/admin/all")
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// get user
export const getAuthUser = () => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .get(`/user/authuser`)
    .then((res) => {
      dispatch(setCurrentUser(res.data));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

//block user
export const bloqueUser = (id) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .put(`/user/bloque/${id}`)
    .then((res) => {
      dispatch(getUser());
      dispatch({
        type: GET_ERRORS,
        payload: { bloque: "ok" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//delete block user
export const validUser = (id) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .put(`/user/valid/${id}`)
    .then((res) => {
      dispatch(getUser());
      dispatch({
        type: GET_ERRORS,
        payload: { valid: "ok" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//get conge

export const getConge = () => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .get("/conge")
    .then((res) => {
      dispatch({
        type: GET_CONGE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

//edit conge
export const congeValide = (id) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .put(`/conge/edit/${id}`)
    .then((res) => {
      dispatch(getConge());
      dispatch({
        type: GET_ERRORS,
        payload: { conge: "ok" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//get conge

export const getAuto = () => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .get("/auto")
    .then((res) => {
      dispatch({
        type: GET_AUTO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

//edit auto
export const autoValide = (id,response) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .put(`/auto/edit/${id}`,response)
    .then((res) => {
      dispatch(getAuto());
      dispatch({
        type: GET_ERRORS,
        payload: { auto: "ok" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// get logged in user
//   export const getCurrentUser = () =>(dispatch) => {
//     setAuthToken(localStorage.token)
//     axios
//       .get("/user")
//       .then((res) => {
//         dispatch(setCurrentUser(res.data))
//       })
//       .catch((err) =>
//         dispatch({
//           type: GET_ERRORS,
//           payload: err.response.data,
//         })
//       );
//   };

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  // localStorage.removeItem("token");
  localStorage.clear();
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
