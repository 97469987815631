import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { editOffre } from "../actions/offreaction";
import { GET_ERRORS } from "../actions/types";
import { getOffreByID } from "./../actions/offreaction";

function Offre({ match }) {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.errors);
  const job = useSelector((state) => state.offres.offre);
  const [offre, setOffre] = useState({});
  const [typeEmploi, setTypeEmploi] = useState({
    CDI: "",
    CDD: "",
    tempsPlein: "",
    tempsPartiel: "",
    freelance: "",
    stage: "",
    alternance: "",
    interim: "",
    error: false,
  });
  const [langue, setLangue] = useState({
    francais: "",
    anglais: "",
  });
  const [offreErreur, setOffreErreur] = useState(true);
  const [valideForm, setValidForm] = useState({
    titre: false,
    nbPostes: false,
    description: false,
    catégorie: false,
    emplacement: false,
    email: false,
    dateExpiration: "",
  });
  const [btnForm, setBtnForm] = useState(false);

  const form = useRef();

  useEffect(() => {
    dispatch(getOffreByID(match.params.offreId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (job) {
      setOffre(job);
      let arrayOfTypes = [];
      arrayOfTypes = job?.typeEmploi?.split(",");
      if (arrayOfTypes && arrayOfTypes.length > 0) {
        arrayOfTypes.forEach((element) => {
          if (element === "CDI")
            setTypeEmploi((prevState) => ({
              ...prevState,
              CDI: element,
            }));
          else if (element === "CDD")
            setTypeEmploi((prevState) => ({
              ...prevState,
              CDD: element,
            }));
          else if (element === "Temps plein")
            setTypeEmploi((prevState) => ({
              ...prevState,
              tempsPlein: element,
            }));
          else if (element === "Temps partiel")
            setTypeEmploi((prevState) => ({
              ...prevState,
              tempsPartiel: element,
            }));
          else if (element === "Freelance")
            setTypeEmploi((prevState) => ({
              ...prevState,
              freelance: element,
            }));
          else if (element === "Stage")
            setTypeEmploi((prevState) => ({
              ...prevState,
              stage: element,
            }));
          else if (element === "Alternance")
            setTypeEmploi((prevState) => ({
              ...prevState,
              alternance: element,
            }));
          else if (element === "Intérim")
            setTypeEmploi((prevState) => ({
              ...prevState,
              interim: element,
            }));
        });
      }

      let arrayOfLangues = [];
      arrayOfLangues = job?.langue?.split(",");
      if (arrayOfLangues && arrayOfLangues.length > 0) {
        arrayOfLangues.forEach((element) => {
          if (element === "Français")
            setLangue((prevState) => ({
              ...prevState,
              francais: element,
            }));
          else if (element === "Anglais")
            setLangue((prevState) => ({
              ...prevState,
              anglais: element,
            }));
        });
      }
    }
  }, [job]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (error.offre) {
      setOffre({
        ...offre,
        titre: "",
        nbPostes: "",
        description: "",
        missions: "",
        exigence: "",
        catégorie: "",
        emplacement: "",
        pays: "France",
        gouvernorat: "",
        codePostal: "",
        email: "",
        dateExpiration: "",
        experience: "",
        niveauEtude: "",
        rémunérationProposée: "",
      });
      setTypeEmploi({
        ...typeEmploi,
        CDI: "",
        CDD: "",
        tempsPlein: "",
        tempsPartiel: "",
        freelance: "",
        stage: "",
        alternance: "",
        interim: "",
        error: false,
      });
      setLangue({
        ...langue,
        francais: "",
        anglais: "",
      });
      setOffreErreur(false);
      setTimeout(() => {
        setOffreErreur(true);
      }, 3300);
      setBtnForm(false);
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    }
  });
  const onChange = (e) => {
    setOffre({ ...offre, [e.target.id]: e.target.value });
    setValidForm({ ...valideForm, [e.target.id]: false });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (
      offre.titre.length !== 0 &&
      offre.description.length !== 0 &&
      offre.nbPostes.length !== 0 &&
      (typeEmploi.CDD.length !== 0 ||
        typeEmploi.CDI.length !== 0 ||
        typeEmploi.tempsPartiel.length !== 0 ||
        typeEmploi.tempsPlein.length !== 0 ||
        typeEmploi.freelance.length !== 0 ||
        typeEmploi.stage.length !== 0 ||
        typeEmploi.alternance.length !== 0 ||
        typeEmploi.interim.length !== 0) &&
      offre.catégorie.length !== 0 &&
      offre.emplacement.length !== 0 &&
      offre.email.length !== 0 &&
      offre.dateExpiration.length !== 0
    ) {
      setBtnForm(true);
      let newOffre = {
        titre: offre.titre.trim(),
        nbPostes: offre.nbPostes.trim(),
        description: offre.description.trim(),
        missions: offre.missions.trim(),
        exigence: offre.exigence.trim(),
        typeEmploi: (
          typeEmploi.CDD +
          (typeEmploi.CDD && ",") +
          typeEmploi.CDI +
          (typeEmploi.CDI && ",") +
          typeEmploi.tempsPartiel +
          (typeEmploi.tempsPartiel && ",") +
          typeEmploi.tempsPlein +
          (typeEmploi.tempsPlein && ",") +
          typeEmploi.freelance +
          (typeEmploi.freelance && ",") +
          typeEmploi.stage +
          (typeEmploi.stage && ",") +
          typeEmploi.alternance +
          (typeEmploi.alternance && ",") +
          typeEmploi.interim
        ).trim(),
        catégorie: offre.catégorie,
        emplacement: offre.emplacement.trim(),
        pays: "France",
        gouvernorat: offre.gouvernorat,
        codePostal: offre.codePostal.trim(),
        email: offre.email.trim(),
        dateExpiration: offre.dateExpiration,
        experience: offre.experience,
        niveauEtude: offre.niveauEtude,
        rémunérationProposée: offre.rémunérationProposée,
        langue: (
          langue.francais +
          (langue.francais && ",") +
          langue.anglais
        ).trim(),
      };
      dispatch(editOffre(newOffre, job._id));
    } else if (
      offre.titre.length === 0 &&
      offre.description.length === 0 &&
      offre.nbPostes.length === 0 &&
      offre.catégorie.length === 0 &&
      offre.emplacement.length === 0 &&
      offre.email.length === 0 &&
      offre.dateExpiration.length === 0
    ) {
      setValidForm({
        ...valideForm,
        titre: true,
        description: true,
        nbPostes: true,
        catégorie: true,
        emplacement: true,
        email: true,
        dateExpiration: true,
      });
    } else if (offre.titre.length === 0) {
      setValidForm({ ...valideForm, titre: true });
    } else if (offre.description.length === 0) {
      setValidForm({ ...valideForm, description: true });
    } else if (offre.nbPostes.length === 0) {
      setValidForm({ ...valideForm, nbPostes: true });
    } else if (offre.catégorie.length === 0) {
      setValidForm({ ...valideForm, catégorie: true });
    } else if (offre.emplacement.length === 0) {
      setValidForm({ ...valideForm, emplacement: true });
    } else if (offre.email.length === 0) {
      setValidForm({ ...valideForm, email: true });
    } else if (offre.dateExpiration.length === 0) {
      setValidForm({ ...valideForm, dateExpiration: true });
    }

    if (
      typeEmploi.CDD.length === 0 &&
      typeEmploi.CDI.length === 0 &&
      typeEmploi.tempsPartiel.length === 0 &&
      typeEmploi.tempsPlein.length === 0 &&
      typeEmploi.freelance.length === 0 &&
      typeEmploi.stage.length === 0 &&
      typeEmploi.alternance.length === 0 &&
      typeEmploi.interim.length === 0
    )
      setTypeEmploi({ ...typeEmploi, error: true });
  };
  return (
    <div className="dash">
      <Link to="/dashboard">
        <img src="/sources/arrow.svg" alt="back" />
      </Link>

      <div className="modifier">
        <h3>Modifier l'offre</h3>
      </div>
      {job && new Date(job.dateExpiration) <= new Date() && (
        <div className="expiration-offre">
          <p>
            <i className="fas fa-exclamation-circle"></i>
            L'offre a expiré.
          </p>
        </div>
      )}
      <form onSubmit={onSubmit} ref={form}>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="titre">
            titre*
          </label>
          <input
            autoFocus
            className="form-control"
            id="titre"
            onChange={onChange}
            defaultValue={offre.titre}
            style={{ border: valideForm.titre && "1px solid red" }}
          />
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="nbPostes">
            Nombre de postes vacants*
          </label>
          <input
            className="form-control"
            id="nbPostes"
            type="number"
            min="1"
            onChange={onChange}
            defaultValue={offre.nbPostes}
            style={{ border: valideForm.nbPostes && "1px solid red" }}
          />
        </div>
        <div className="form-outline mb-4">
          <label className="form-label">Type d'emploi désiré*</label>
          <div
            style={{
              border: typeEmploi.error && "1px solid red",
              padding: 4,
              borderRadius: 5,
            }}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="CDI"
                id="CDI"
                onChange={(e) => {
                  e.target.checked
                    ? setTypeEmploi({
                        ...typeEmploi,
                        [e.target.id]: e.target.value,
                        error: false,
                      })
                    : setTypeEmploi({ ...typeEmploi, [e.target.id]: "" });
                }}
                checked={typeEmploi.CDI === "CDI"}
              />
              <label className="form-check-label" htmlFor="CDI">
                CDI
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="CDD"
                id="CDD"
                checked={typeEmploi.CDD === "CDD"}
                onChange={(e) => {
                  e.target.checked
                    ? setTypeEmploi({
                        ...typeEmploi,
                        [e.target.id]: e.target.value,
                        error: false,
                      })
                    : setTypeEmploi({ ...typeEmploi, [e.target.id]: "" });
                }}
              />
              <label className="form-check-label" htmlFor="CDD">
                CDD
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Temps plein"
                id="tempsPlein"
                checked={typeEmploi.tempsPlein === "Temps plein"}
                onChange={(e) =>
                  e.target.checked
                    ? setTypeEmploi({
                        ...typeEmploi,
                        [e.target.id]: e.target.value,
                        error: false,
                      })
                    : setTypeEmploi({ ...typeEmploi, [e.target.id]: "" })
                }
              />
              <label className="form-check-label" htmlFor="tempsPlein">
                Temps plein
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Temps partiel"
                id="tempsPartiel"
                checked={typeEmploi.tempsPartiel === "Temps partiel"}
                onChange={(e) =>
                  e.target.checked
                    ? setTypeEmploi({
                        ...typeEmploi,
                        [e.target.id]: e.target.value,
                        error: false,
                      })
                    : setTypeEmploi({ ...typeEmploi, [e.target.id]: "" })
                }
              />
              <label className="form-check-label" htmlFor="tempsPartiel">
                Temps partiel
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Freelance"
                id="freelance"
                checked={typeEmploi.freelance === "Freelance"}
                onChange={(e) =>
                  e.target.checked
                    ? setTypeEmploi({
                        ...typeEmploi,
                        [e.target.id]: e.target.value,
                        error: false,
                      })
                    : setTypeEmploi({ ...typeEmploi, [e.target.id]: "" })
                }
              />
              <label className="form-check-label" htmlFor="freelance">
                Freelance
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Stage"
                id="stage"
                checked={typeEmploi.stage === "Stage"}
                onChange={(e) =>
                  e.target.checked
                    ? setTypeEmploi({
                        ...typeEmploi,
                        [e.target.id]: e.target.value,
                        error: false,
                      })
                    : setTypeEmploi({ ...typeEmploi, [e.target.id]: "" })
                }
              />
              <label className="form-check-label" htmlFor="stage">
                Stage
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Alternance"
                id="alternance"
                checked={typeEmploi.alternance === "Alternance"}
                onChange={(e) =>
                  e.target.checked
                    ? setTypeEmploi({
                        ...typeEmploi,
                        [e.target.id]: e.target.value,
                        error: false,
                      })
                    : setTypeEmploi({ ...typeEmploi, [e.target.id]: "" })
                }
              />
              <label className="form-check-label" htmlFor="alternance">
                Alternance
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Intérim"
                id="interim"
                checked={typeEmploi.interim === "Intérim"}
                onChange={(e) =>
                  e.target.checked
                    ? setTypeEmploi({
                        ...typeEmploi,
                        [e.target.id]: e.target.value,
                        error: false,
                      })
                    : setTypeEmploi({ ...typeEmploi, [e.target.id]: "" })
                }
              />
              <label className="form-check-label" htmlFor="interim">
                Intérim
              </label>
            </div>
          </div>
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="catégorie">
            Catégories*
          </label>
          <select
            id="catégorie"
            onChange={onChange}
            value={offre.catégorie}
            className="form-control"
            style={{ border: valideForm.catégorie && "1px solid red" }}
          >
            <option value="">Cliquer pour sélectionner</option>
            <option value="Développement web">Développement web</option>
            <option value="Réseau et sécurité">Réseau et sécurité</option>
            <option value="Gestion et management de Projet">
              Gestion et management de Projet
            </option>
            <option value="Système d'Information">Système d'Information</option>
            <option value="Solution CLOUD">Solution CLOUD</option>
            <option value="Data center">Data center</option>
            <option value="Data engineering">Data engineering</option>
          </select>
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="emplacement">
            Emplacement (Adresse)*
          </label>
          <input
            className="form-control"
            id="emplacement"
            onChange={onChange}
            defaultValue={offre.emplacement}
            style={{ border: valideForm.emplacement && "1px solid red" }}
          />
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="description">
            Description*
          </label>
          <textarea
            className="form-control"
            id="description"
            rows="6"
            onChange={onChange}
            defaultValue={offre.description}
            style={{ border: valideForm.description && "1px solid red" }}
          ></textarea>
        </div>

        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="pays">
            Pays
          </label>
          <input className="form-control" id="pays" value="France" disabled />
          {/* <select
            id="pays"
            onChange={onChange}
            value={offre.pays}
            className="form-control"
          >
            <option value="">Cliquer pour sélectionner</option>
            <option value="Tunisie">Tunisie</option>
            <option value="France">France</option>
          </select> */}
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="gouvernorat">
            {/* {(offre.pays === "Tunisie" || offre.pays === "") && "Gouvernorat"} */}
            Régions métropolitaines
          </label>
          {/* {offre.pays === "Tunisie" && (
            <select
              id="gouvernorat"
              onChange={onChange}
              defaultValue={offre.gouvernorat}
              className="form-control"
            >
              <option value="">Cliquer pour sélectionner</option>
              <option value="Ariana">Ariana</option>
              <option value="Béja">Béja</option>
              <option value="Ben Arous">Ben Arous</option>
              <option value="Bizerte">Bizerte</option>
              <option value="Gabès">Gabès</option>
              <option value="Gafsa">Gafsa</option>
              <option value="Jendouba">Jendouba</option>
              <option value="Kairouan">Kairouan</option>
              <option value="Kasserine">Kasserine</option>
              <option value="Kébili">Kébili</option>
              <option value="Le Kef">Le Kef</option>
              <option value="Mahdia">Mahdia</option>
              <option value="La Manouba">La Manouba</option>
              <option value="Médenine">Médenine</option>
              <option value="Monastir">Monastir</option>
              <option value="Nabeul">Nabeul</option>
              <option value="Sfax">Sfax</option>
              <option value="Sidi Bouzid">Sidi Bouzid</option>
              <option value="Siliana">Siliana</option>
              <option value="Sousse">Sousse</option>
              <option value="Tataouine">Tataouine</option>
              <option value="Tozeur">Tozeur</option>
              <option value="Tunis">Tunis</option>
              <option value="Zaghouan">Zaghouan</option>
            </select>
          )} */}
          {/* {offre.pays === "France" && ( */}
            <select
              id="gouvernorat"
              onChange={onChange}
              value={offre.gouvernorat}
              className="form-control"
            >
              <option value="">Cliquer pour sélectionner</option>
              <option value="Auvergne-Rhône-Alpes">Auvergne-Rhône-Alpes</option>
              <option value="Bourgogne-Franche-Comté">
                Bourgogne-Franche-Comté
              </option>
              <option value="Bretagne">Bretagne</option>
              <option value="Centre-Val de Loire">Centre-Val de Loire</option>
              <option value="Corse">Corse</option>
              <option value="Grand Est">Grand Est</option>
              <option value="Hauts-de-France">Hauts-de-France</option>
              <option value="Île-de-France">Île-de-France</option>
              <option value="Normandie">Normandie</option>
              <option value="Nouvelle-Aquitaine">Nouvelle-Aquitaine</option>
              <option value="Occitanie">Occitanie</option>
              <option value="Pays de la Loire">Pays de la Loire</option>
              <option value="Provence-Alpes-Côte d'Azur">
                Provence-Alpes-Côte d'Azur
              </option>
            </select>
          {/* )} */}
          {/* {offre.pays === "" && (
            <select
              id="gouvernorat"
              onChange={onChange}
              defaultValue={offre.gouvernorat}
              className="form-control"
            >
              <option value="">Cliquer pour sélectionner</option>
            </select>
          )} */}
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="codePostal">
            Code postal
          </label>
          <input
            className="form-control"
            id="codePostal"
            onChange={onChange}
            defaultValue={offre.codePostal}
          />
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="missions">
            Missions
          </label>
          <textarea
            className="form-control"
            id="missions"
            rows="5"
            onChange={onChange}
            defaultValue={offre.missions}
          ></textarea>
        </div>

        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="exigence">
            Exigence de l'emploi
          </label>
          <textarea
            className="form-control"
            id="exigence"
            rows="5"
            onChange={onChange}
            defaultValue={offre.exigence}
          ></textarea>
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="email">
            E-mail pour postuler*
          </label>
          <input
            className="form-control"
            id="email"
            onChange={onChange}
            defaultValue={offre.email}
            style={{ border: valideForm.email && "1px solid red" }}
            type="email"
          />
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="dateExpiration">
            Date d'expiration*
          </label>
          <input
            className="form-control"
            type="date"
            id="dateExpiration"
            defaultValue={offre.dateExpiration}
            onChange={onChange}
            style={{ border: valideForm.dateExpiration && "1px solid red" }}
          />
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="experience">
            Experience
          </label>
          <select
            id="experience"
            onChange={onChange}
            value={offre.experience}
            className="form-control"
          >
            <option value="">Sélectionner Expérience</option>
            <option value="Débutant">Débutant</option>
            <option value="1 à 3 ans">1 à 3 ans</option>
            <option value="3 ans à 5 ans">3 ans à 5 ans</option>
            <option value="plus de 5 ans">Plus de 5 ans</option>
          </select>
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="niveauEtude">
            Niveau d'étude
          </label>
          <select
            id="niveauEtude"
            onChange={onChange}
            value={offre.niveauEtude}
            className="form-control"
          >
            <option value="">Sélectionner Niveau d'étude</option>
            <option value="Lycée, Niveau Bac">Lycée, Niveau Bac</option>
            <option value="BTS, Bac + 2">BTS, Bac + 2</option>
            <option value="Licence, Bac + 3">Licence, Bac + 3</option>
            <option value="Maîtrise, Bac + 4">Maîtrise, Bac + 4</option>
            <option value="Master, Bac + 5">Master, Bac + 5</option>
            <option value="Ingénieur">Ingénieur</option>
            <option value="Doctorat">Doctorat</option>
          </select>
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="rémunérationProposée">
            Rémunération proposée
          </label>
          <input
            className="form-control"
            id="rémunérationProposée"
            onChange={onChange}
            value={offre.rémunérationProposée}
          />
          {/* {offre.pays === "France" && (
            <select
              id="rémunérationProposée"
              onChange={onChange}
              value={offre.rémunérationProposée}
              className="form-control"
            >
              <option value="">Sélectionner Rémunération proposée</option>
              <option value="Entre 20 K et 25 K">Entre 20 K et 25 K</option>
              <option value="Entre 25 K et 30 K">Entre 25 K et 30 K</option>
              <option value="Entre 30 K et 35 K">Entre 30 K et 35 K</option>
              <option value="Entre 35 K et 40 K">Entre 35 K et 40 K</option>
              <option value="40 K et plus">40 K et plus</option>
            </select>
          )}
          {offre.pays === "Tunisie" && (
            <select
              id="rémunérationProposée"
              onChange={onChange}
              value={offre.rémunérationProposée}
              className="form-control"
            >
              <option value="">Sélectionner Rémunération proposée</option>
              <option value="Entre 400 TND et 600 TND">
                Entre 400 TND et 600 TND
              </option>
              <option value="Entre 600 TND et 1000 TND">
                Entre 600 TND et 1000 TND
              </option>
              <option value="Entre 1000 TND et 1500 TND">
                Entre 1000 TND et 1500 TND
              </option>
              <option value="Entre 1500 TND et 2000 TND">
                Entre 1500 TND et 2000 TND
              </option>
              <option value="2000 TND et plus">2000 TND et plus</option>
            </select>
          )}
          {offre.pays === "" && (
            <select
              id="rémunérationProposée"
              onChange={onChange}
              value={offre.rémunérationProposée}
              className="form-control"
            >
              <option value="">Sélectionner Rémunération proposée</option>
              <option value="Entre 400 TND et 600 TND">
                Entre 400 TND et 600 TND
              </option>
              <option value="Entre 600 TND et 1000 TND">
                Entre 600 TND et 1000 TND
              </option>
              <option value="Entre 1000 TND et 1500 TND">
                Entre 1000 TND et 1500 TND
              </option>
              <option value="Entre 1500 TND et 2000 TND">
                Entre 1500 TND et 2000 TND
              </option>
              <option value="2000 TND et plus">2000 TND et plus</option>
            </select>
          )} */}
        </div>
        <div className="form-outline mb-4">
          <label className="form-label">Langue</label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Français"
              id="francais"
              onChange={(e) => {
                e.target.checked
                  ? setLangue({
                      ...langue,
                      [e.target.id]: e.target.value,
                    })
                  : setLangue({ ...langue, [e.target.id]: "" });
              }}
              checked={langue.francais === "Français"}
            />
            <label className="form-check-label" htmlFor="francais">
              Français
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Anglais"
              id="anglais"
              checked={langue.anglais === "Anglais"}
              onChange={(e) => {
                e.target.checked
                  ? setLangue({
                      ...langue,
                      [e.target.id]: e.target.value,
                    })
                  : setLangue({ ...langue, [e.target.id]: "" });
              }}
            />
            <label className="form-check-label" htmlFor="anglais">
              Anglais
            </label>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "start",
            color: "gray",
            marginBottom: 10,
          }}
        >
          *Champs requis
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-block mb-4"
          disabled={btnForm}
          style={{ width: 150, margin: "auto" }}
        >
          Envoyer
        </button>
        <div
          className="erreur-form"
          style={{
            display:
              !valideForm.titre &&
              !valideForm.description &&
              !valideForm.nbPostes &&
              !typeEmploi.error &&
              !valideForm.catégorie &&
              !valideForm.emplacement &&
              !valideForm.email &&
              !valideForm.dateExpiration &&
              "none",
          }}
        >
          <p>
            Un ou plusieurs champs contiennent une erreur. Veuillez vérifier et
            essayer à nouveau
          </p>
        </div>
      </form>
      <div
        className="offre-alert"
        style={{
          opacity: offreErreur && 0,
          visibility: offreErreur && "hidden",
        }}
      >
        La description de poste a été modifiée avec succès
      </div>
    </div>
  );
}

export default Offre;
