import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getAuto, autoValide } from "../actions/authaction";
import "../style/conge.css";
import { GET_ERRORS } from "../actions/types";

function AutoManagement() {
  const dispatch = useDispatch();
  const auto = useSelector((state) => state.auto.auto);
  const error = useSelector((state) => state.errors);
  const users = useSelector((state) => state.users.users);
  const [autoId, setAutoId] = useState("");
  const [response, setResponse] = useState("");
  const [modal, setModal] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);

  const onChange = (e) => {
    setResponse(e.target.value);
  };

  useEffect(() => {
    dispatch(getAuto());
    dispatch(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (error.auto) {
      toggle();
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    }
  });
  const toggle = () => {
    setModal(!modal);
  };
  const valideAuto = (id) => {
    if (response) {
      let data = {
        response: response,
      };
      dispatch(autoValide(id, data));
    } else {
      setAutoId(id);
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      <div className="add-offre">
        <h3>Gestion des demande particulière</h3>
      </div>
      {auto && users.length && auto.length !== 0 ? (
        <>
          {auto
            .slice(0)
            .reverse()
            .map((el, id) => {
              return (
                <div key={el._id} className="collap-conge">
                  <div>
                    <div>
                      <img
                        src={users.find((elm) => elm._id === el.userId)?.avatar}
                        alt="avatar"
                      />
                    </div>

                    <div>
                      {users.find((elm) => elm._id === el.userId)?.fname +
                        " " +
                        users.find((elm) => elm._id === el.userId)?.lname}
                    </div>
                  </div>
                  <div>
                    <div>
                      {/* <img src="/sources/calendar.png" alt="calendar" /> */}
                    </div>
                    <div>
                      <p>{el.raison ? el.raison : "Rien à afficher"}</p>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="response">Réponse</label>
                    <input
                      onChange={onChange}
                      id="response"
                      type="text"
                      className="form-control"
                    />
                    {el._id === autoId ? (
                      <span style={{ color: "red" }}>
                        Écrivez votre réponse ici
                      </span>
                    ) : null}
                  </div>
                  <div>
                    {/* <span style={{ color: el.etat ? "#37c95c" : "red" }}>
                      {el.etat ? "Validé" : "Non validé"}
                    </span> */}
                    {!el.etat ? (
                      <img
                        src="/sources/valider.png"
                        alt="valider"
                        title="Envoyer"
                        onClick={() => {
                          valideAuto(el._id);
                          // toggle();
                          // setAutoId(el._id);
                        }}
                      ></img>
                    ) : null}
                  </div>
                </div>
              );
            })}{" "}
        </>
      ) : (
        auto && (
          <div className="no-offre">
            <p>
              <i className="fas fa-exclamation"></i>
              Liste vide.
            </p>
          </div>
        )
      )}
      {/* <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>
          Valider la demande d'autorisation
        </MDBModalHeader>
        <MDBModalBody>
          êtes-vous sûr de vouloir valider la demande ?
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            className="no-delete"
            onClick={toggle}
            style={{
              color: "#0380b1",
              border: "1px solid #0380b1",
              borderRadius: 5,
            }}
          >
            Fermer
          </MDBBtn>
          <MDBBtn
            className="yes-delete"
            onClick={() => valideAuto(autoId)}
            style={{ color: "white", borderRadius: 5 }}
          >
            Valider
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal> */}
    </div>
  );
}

export default AutoManagement;
