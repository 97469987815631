import React, { useEffect, useState } from "react";
import { Paper, withStyles } from "@material-ui/core";
import { DeleteOutline, Edit, Visibility, Send } from "@material-ui/icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { deleteTest, getTestList } from "../../../actions/testAction";
import Dialog from "./Dialog";
import SendQcmDialog from "./sendQcmDialog";
import LoadingSpinner from "../../../component/LoadingSpinner";

const styleManagement = (theme) => ({
  rootIndex: {
    border: `8px solid ${theme.palette.common.white}`,
    margin: 16,
    padding: "36px 0 0",
    background: `rgba(255,255,255,0.9)`,
    boxShadow: [
      `0px 16px 26px -10px ${theme.palette.primary.main}99`,
      theme.shadows[15],
    ],
  },
  navigationIndex: {
    width: 110,
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      width: 90,
    },
  },
});

const columns = [
  { id: "title", label: "Titre", align: "center" },
  { id: "nbQuestions", label: "Nombre de questions", align: "right" },
  { id: "duration", label: "Durée", align: "right" },
  { id: "action", label: "Action", align: "center" },
];

const ManageQcms = ({ history, classes }) => {
  const tests = useSelector((state) => state.qcmTest.qcmList);
  const error = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [idTest, setIdTest] = useState();
  const [loader, setLoader] = useState(false);
  const [openSendQCM, setOpenSendQCM] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = (idTest) => {
    setIdTest(idTest);
    setOpenDialog(true);
  };

  const handleClickSave = () => {
    dispatch(deleteTest(idTest));
    setOpenDialog(false);
  };

  const handleClickClose = () => {
    setOpenDialog(false);
  };

  const handleSendQCMOpen = (idTest) => {
    setIdTest(idTest);
    setOpenSendQCM(true);
  };
  const handleSendQCMClose = () => {
    setOpenSendQCM(false);
  };

  useEffect(() => {
    if (tests) {
      setRows(tests);
    }
    setLoader(false);
  }, [tests]);

  useEffect(() => {
    setLoader(true);
    dispatch(getTestList());
  }, []);

  useEffect(() => {}, [error]);

  console.log("error", error);
  return (
    <Paper elevation={1} className={classes.rootIndex}>
      <div>
        <img
          src="/sources/arrow.svg"
          alt="back"
          style={{ cursor: "pointer", marginBottom: 20 }}
          onClick={() => history.push("/qcm")}
        />
      </div>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loader ? <LoadingSpinner /> : null}
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .reverse()
              .map((row) => {
                let hours = Math.floor(row.duration / 3600);
                let minutes = Math.floor((row.duration % 3600) / 60);
                let seconds = Math.floor((row.duration % 3600) % 60);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell align="right">{row.nbQuestions}</TableCell>
                    <TableCell align="right">
                      {" "}
                      {hours}:{minutes}:{seconds}{" "}
                    </TableCell>
                    <TableCell align="center">
                      <Visibility
                        title="Visibilité"
                        onClick={() => history.push("/viewQCM/" + row._id)}
                      />{" "}
                      <Edit
                        title="Modifier"
                        style={{ color: "#037baa" }}
                        onClick={() => history.push("/updateQCM/" + row._id)}
                      />{" "}
                      <Send
                        style={{ color: "green" }}
                        title="Envoyer"
                        onClick={() => handleSendQCMOpen(row._id)}
                      />{" "}
                      <DeleteOutline
                        title="Supprimer"
                        style={{ color: "red" }}
                        onClick={() => handleClickOpen(row._id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {openDialog && idTest ? (
        <Dialog
          open={openDialog}
          handleClickClose={handleClickClose}
          handleClickSave={handleClickSave}
          title="Suppression de test"
        />
      ) : null}

      {openSendQCM && idTest ? (
        <SendQcmDialog
          open={openSendQCM}
          handleClickClose={handleSendQCMClose}
          idTest={idTest}
        />
      ) : null}
      {/* {error ? (
        <div
          className="offre-alert"
          style={{
            opacity: error ? 100 : 0,
            visibility: error ? "visible" : "hidden",
            backgroundColor: "red",
          }}
        >
          {error}
        </div>
      ) : null} */}
    </Paper>
  );
};
export default withStyles(styleManagement)(ManageQcms);
