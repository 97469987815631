import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./component/PrivateRoute";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Offre from "./pages/Offre";
// import Register from "./pages/Register";
// import Unvalid from "./pages/Unvalid";
import { useDispatch, useSelector } from "react-redux";
import { ACCES } from "./actions/types";
import Home from "./pages/Home";
import QCM from './pages/Qcm/index';
import Content from './pages/Qcm/AddQcm';
import UpdateQcm from './pages/Qcm/UpdateQcm/index';
import ManageQcms from "./pages/Qcm/ManageQcms";
import viewQCM from "./pages/Qcm/ViewQCM";
import ResultsQcms from "./pages/Qcm/ResultsQcms";
function App() {
  const [offreErreur, setOffreErreur] = useState(true);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // window.addEventListener("beforeunload", () =>
    //   localStorage.removeItem("token")
    // );
    if (auth.acces) {
      setOffreErreur(false);
      setTimeout(() => {
        setOffreErreur(true);
      }, 3300);
      dispatch({
        type: ACCES,
        payload: false,
      });
    }
  });
  return (
    <div className="App">
      <img src="/sources/logo.png" alt="logo" />
      {/* <span>Dashboard</span> */}
      <Switch>
        <Route exact path="/" component={Login} />
        {/* <Route exact path="/register" component={Register} /> */}
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/qcm" component={QCM} />
        <PrivateRoute path="/addQCM" component={Content} />
        <PrivateRoute path="/manageQCM" component={ManageQcms} />
        <PrivateRoute path="/updateQCM/:qcmId" component={UpdateQcm} />
        <PrivateRoute path="/viewQCM/:qcmId" component={viewQCM} />
        <PrivateRoute path="/resultQCM" component={ResultsQcms} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/offres/:offreId" component={Offre} />
        <Route path="*" component={Home} />
      </Switch>
      <div
        className="offre-alert"
        style={{
          opacity: offreErreur && 0,
          visibility: offreErreur && "hidden",
          backgroundColor: "red",
        }}
      >
        vous n'êtes pas autorisé
      </div>
    </div>
  );
}

export default App;
