import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getTest } from "../../../actions/testAction";
import "../../../style/viewQcm.css";
import LoadingSpinner from './../../../component/LoadingSpinner';
const useStyles = makeStyles((theme) => ({
  rootIndex: {
    border: `8px solid ${theme.palette.common.white}`,
    margin: 16,
    padding: "36px 0 0",
    background: `rgba(255,255,255,0.9)`,
    boxShadow: [
      `0px 16px 26px -10px ${theme.palette.primary.main}99`,
      theme.shadows[15],
    ],
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const ViewQcm = ({ match, history }) => {
  const [loader, setLoader] = useState(false);
  const classes = useStyles();
  const test = useSelector((state) => state.qcmTest.qcm);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    dispatch(getTest(match.params.qcmId));
  }, []);

  useEffect(() => {
    if (test) {
      setLoader(false);
    }
  }, [test]);

  return (
    <Paper elevation={1} className={classes.rootIndex}>
      {loader ? (
        <LoadingSpinner />
      ) : (
        <Grid container>
          <Grid item xs={3}>
            <img
              src="/sources/arrow.svg"
              alt="back"
              style={{ cursor: "pointer", marginBottom: 20 }}
              onClick={() => history.push("/manageQCM")}
            />
          </Grid>
          <Grid item xs={12}>
            <h1 className="title">{test.title}</h1>
            <br />
            <ol>
              {test.questions?.map((qst) => {
                let date = new Date(qst.duration);
                let minutes = date.getMinutes();
                let seconds = date.getSeconds();

                return (
                  <li style={{ marginBottom: "2%" }}>
                    <span className="qstAlign">
                      <h6 className="qst">{qst.question}</h6>
                      <span className="time">
                        {"("}
                        {minutes}:{seconds}
                        {")"}
                      </span>
                    </span>

                    {qst.nbPropositions > 0 ? (
                      <div className="alert-message answer">
                        <ul>
                          {qst.propositions?.map((prop) => (
                            <li style={{ marginBottom: 10 }}>
                              <b>
                                {prop.proposition}
                                {prop.isCorrect ? (
                                  <Check style={{ color: "green" }} />
                                ) : null}
                              </b>{" "}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </li>
                );
              })}
            </ol>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};
export default ViewQcm;
