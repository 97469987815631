import axios from "axios";
import setAuthToken from "../token/authtoken";
import { GET_ERRORS, GET_TEST, GET_TESTS, TEST_SENT } from "./types";

export const addTest = (testData, history) => (dispatch) => {

  setAuthToken(localStorage.token);
  axios
    .post("/test/add", testData)
    .then((res) => {
      dispatch({
        type: GET_TEST,
        payload: res.data,
      });
      localStorage.removeItem("step");
      localStorage.removeItem("qstNum");
      localStorage.removeItem("questions");
      localStorage.removeItem("testTitle");
      history.push("/qcm");
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getTest = (id) => (dispatch) => {
  axios
    .get(`/test/${id}`)
    .then((res) => {
      dispatch({
        type: GET_TEST,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getTestList = () => (dispatch) => {
  axios
    .get("/test")
    .then((res) => {
      dispatch({
        type: GET_TESTS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const deleteTest = (id) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .delete(`/test/delete/${id}`)
    .then((res) => {
      dispatch({
        type: GET_TESTS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const editTest = (testData, id, history) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .put(`/test/edit/${id}`, testData)
    .then((res) => {
      dispatch({
        type: GET_TEST,
        payload: res.data,
      });
      localStorage.removeItem("qstNum");
      localStorage.removeItem("step");      
      localStorage.removeItem("questions");
      localStorage.removeItem("testTitle");
      history.push("/manageQCM");
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const sendTest = (data) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .post("/test/sendTest", data)
    .then((res) => {
      dispatch({
        type: TEST_SENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
