import axios from "axios";
import setAuthToken from "../token/authtoken";
import { GET_ERRORS, GET_OFFRE, GET_ONE_OFFRE } from "./types";

export const addOffre = (offreData) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .post("/offre/add", offreData)
    .then((res) => {
      dispatch({
        type: GET_ERRORS,
        payload: { offre: "ok" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getOffre = () => (dispatch) => {
  axios
    .get("/offre")
    .then((res) => {
      dispatch({
        type: GET_OFFRE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getOffreByID = (id) => (dispatch) => {
  axios
    .get(`/offre/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ONE_OFFRE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const editOffre = (offreData, id) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .put(`/offre/edit/${id}`, offreData)
    .then((res) => {
      dispatch(getOffreByID(id));
      dispatch({
        type: GET_ERRORS,
        payload: { offre: "ok" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const offreDelete = (id) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .delete(`/offre/delete/${id}`)
    .then((res) => {
      dispatch(getOffre());
      dispatch({
        type: GET_ERRORS,
        payload: { delete: "ok" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
