import { combineReducers } from "redux";
import authReducer from "./auth";
import errorReducer from "./error";
import offreReducer from "./offre";
import congeReducer from "./conge";
import usersReducer from "./admin";
import autoReducer from "./auto";
import qcmReducer from "./qcm";
import candidacyReducer from "./candidacy";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  offres: offreReducer,
  conge:congeReducer,
  users:usersReducer,
  auto:autoReducer,
  qcmTest: qcmReducer,
  candidacy: candidacyReducer
});