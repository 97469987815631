import React from "react";
import "../style/home.css";
import TopPage from "./TopPage";

function Home({ history }) {
  return (
    <div className="dash">
      <TopPage />
      <div className="rowHome">
        <div className="_gauche">
          <div className="cardRow" onClick={() => history.push(`/dashboard`)}>
            <img src="/sources/team.png" alt="team" />
            <h3>Catégories</h3>
          </div>
        </div>
        <div className="_droite">
          <div className="cardRow" onClick={() => history.push(`/qcm`)}>
            <img src="/sources/team2.png" alt="team" />
            <h3>QCM</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
