import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { Paper, Grid, withStyles, Button } from "@material-ui/core";
import WizardHeader from "./wizardHeader";
import Test from "./Test";
import Questions from "./Questions";
import { addTest } from "../../../actions/testAction";
import LoadingSpinner from "../../../component/LoadingSpinner";

const styleIndex = (theme) => ({
  rootIndex: {
    border: `8px solid ${theme.palette.common.white}`,
    margin: 16,
    padding: "36px 0 0",
    background: `rgba(255,255,255,0.9)`,
    boxShadow: [
      `0px 16px 26px -10px ${theme.palette.primary.main}99`,
      theme.shadows[15],
    ],
  },
  navigationIndex: {
    width: 110,
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      width: 90,
    },
  },
  prevBtnIndex: {
    color: theme.palette.grey[700],
    background: theme.palette.common.white,
    boxShadow: theme.shadows[5],
  },
});

const Content = ({ history, classes }) => {
  const dispatch = useDispatch();
  const [test, setTest] = useState({
    title: "",
    nbQuestions: 1,
    valid: false,
  });

  const [loader, setLoader] = useState(false);
  const [validQst, setValidQst] = useState(false);
  const [saveLastQst, setSaveLastQst] = useState(false);
  const [activeStep, setActiveStep] = useState(
    localStorage.getItem("step") ? Number(localStorage.getItem("step")) : 0
  );

  const handleChange = (index) => (e) => {
    // setActiveStep(index);
    // localStorage.setItem("step", index);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      localStorage.setItem("testTitle", JSON.stringify(test));
    }
    setActiveStep(activeStep + 1);
    localStorage.setItem("step", activeStep + 1);
  };

  const handlePrev = () => {
    setActiveStep(activeStep - 1);
    localStorage.setItem("step", activeStep - 1);
  };

  const sumDuration = (accumulator, object) => {
    let aDuration = new Date(object.duration);
    let aTotalSeconds =
      parseInt(aDuration.getMinutes()) * 60 + parseInt(aDuration.getSeconds());
    return accumulator + aTotalSeconds;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    if (activeStep === 1) {
      setSaveLastQst(true);
    }
    setTimeout(() => {
      let qcmTitle = JSON.parse(localStorage.getItem("testTitle"));
      let qcmData = JSON.parse(localStorage.getItem("questions"));
      if (qcmTitle && qcmData) {
        let totalTime = qcmData.reduce(sumDuration, 0);
        let data = {
          title: qcmTitle.title,
          nbQuestions: qcmTitle.nbQuestions,
          duration: totalTime,
          questions: qcmData,
        };
        dispatch(addTest(data, history));
        setLoader(true);
      }
    }, 500);
  };

  useEffect(() => {
    let titleData = localStorage.getItem("testTitle");
    if (titleData) setTest(JSON.parse(titleData));
    setValidQst(false);
  }, []);

  const tabs = ["Test", "Questions"];
  return (
    <Paper elevation={1} className={classes.rootIndex}>
      {loader ? <LoadingSpinner /> : null}
      <div>
        <img
          src="/sources/arrow.svg"
          alt="back"
          style={{ cursor: "pointer", marginBottom: 20 }}
          onClick={() => history.push("/qcm")}
        />
      </div>
      <WizardHeader
        tabs={tabs}
        activeStep={activeStep}
        handleChange={handleChange}
      />

      <form>
        <SwipeableViews index={activeStep} onChangeIndex={handleChange}>
          <Test test={test} setTest={setTest} />
          <Questions
            nbQuestions={test.nbQuestions}
            setValidQst={setValidQst}
            saveLastQst={saveLastQst}
          />
        </SwipeableViews>
        <Grid
          container
          justifyContent="space-between"
          style={{ padding: "16px 16px" }}
        >
          <Grid item>
            <Button
              disabled={activeStep === 0}
              onClick={handlePrev}
              variant="contained"
              color="default"
              className={`${classes.navigationIndex} ${classes.prevBtnIndex}`}
            >
              Retour
            </Button>
          </Grid>
          {activeStep < tabs.length - 1 && (
            <Grid item>
              <Button
                type="submit"
                color="primary"
                className={classes.navigationIndex}
                variant="contained"
                onClick={handleNext}
                disabled={activeStep === 0 && !test.valid}
              >
                Continuer
              </Button>
            </Grid>
          )}
          {activeStep === tabs.length - 1 && (
            <Grid item>
              <Button
                type="submit"
                color="primary"
                className={classes.navigationIndex}
                variant="contained"
                onClick={handleSubmit}
                disabled={(activeStep === 1 && !validQst) || loader}
              >
                Sauvegarder
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Paper>
  );
};
export default withStyles(styleIndex)(Content);
